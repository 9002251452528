import { stateKey } from './actions'

export const selectAccessToken = (state) => state[stateKey].accessToken
export const selectRefreshToken = (state) => state[stateKey].refreshToken
export const selectIsRefreshing = (state) => state[stateKey].isRefreshing
export const selectIsFetching = (state) => state[stateKey].isFetching
export const selectIsAuthenticated = (state) => state[stateKey].isAuthenticated
export const selectIsRegistered = (state) => state[stateKey].isRegistered
export const selectErrorMessage = (state) => state[stateKey].errorMessage
export const selectCurrentUser = (state) => state[stateKey].user
export const selectCurrentUserSettings = (state) => state[stateKey].user?.userSettings
export const selectCurrentUserChecked = (state) => state[stateKey].isCurrentChecked
export const selectCurrentUserId = (state) => state[stateKey].user?.id
export const selectForgotPasswordLoading = (state) => state[stateKey].isSendingEmail
export const selectForgotPasswordSuccess = (state) => state[stateKey].isSendingEmailSucces
export const selectForgotPasswordFailure = (state) => state[stateKey].isSendingEmailFailure

export const selectResetPasswordLoading = (state) => state[stateKey].isSendingReset
export const selectResetPasswordSuccess = (state) => state[stateKey].isSendingSuccessReset
export const selectResetPasswordFailure = (state) => state[stateKey].isSendingFailureReset

export const selectApplicationLanguage = (state) => state[stateKey].user?.applicationLanguage
