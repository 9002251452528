import useApplicationSettings from "./useApplicationSettings"

const useLocaleOptions = () => {
    const { availableTranslationLanguages } = useApplicationSettings()
  
    const locales = availableTranslationLanguages.map((lang) => lang.abbreviation)
    localStorage.setItem('translationLangs', JSON.stringify(locales))
  
    const localeOptions = availableTranslationLanguages.map((lang) => ({
      value: lang.abbreviation,
      label: lang.abbreviation.toUpperCase(),
    }))
  
    return localeOptions
  }
  
  export default useLocaleOptions