import { combineReducers } from 'redux'

import Layout from './layouts/reducer'

import { reducer as activities } from './activities'
import { reducer as activityvalues } from './activityValues'
import { reducer as administration } from './administration'
import { reducer as agents } from './agents'
import { reducer as auth } from './auth'
import { reducer as companies } from './companies'
import { reducer as companynotes } from './companyNotes'
import { reducer as contactnotes } from './contactNotes'
import { reducer as contactprofiles } from './contactProfiles'
import { reducer as contacts } from './contacts'
import { reducer as contactvalues } from './contactValues'
import { reducer as diaries } from './diaries'
import { reducer as documents } from './documents'
import { reducer as documentvalues } from './documentValues'
import { reducer as entityvalues } from './entityValues'
import { reducer as exports } from './export'
import { reducer as groundplans } from './groundplans'
import { reducer as imageEditor } from './imageEditor'
import { reducer as locations } from './locations'
import { reducer as modal } from './modal'
import { reducer as notes } from './notes'
import { reducer as offers } from './offers'
import { reducer as photos } from './photos'
import { reducer as projects } from './projects'
import { reducer as projectvalues } from './projectValues'
import { reducer as properties } from './properties'
import { reducer as propertyavailableentities } from './propertyAvailableEntities'
import { reducer as propertyservices } from './propertyServices'
import { reducer as propertyset } from './propertySet'
import { reducer as propertysubtypes } from './propertySubtypes'
import { reducer as propertytypes } from './propertyTypes'
import { reducer as propertyvalues } from './propertyValues'
import { reducer as reports } from './reports'
import { reducer as search } from './search'
import { reducer as searchvalues } from './searchValues'
import { reducer as settings } from './settings'
import { reducer as table } from './table'

const rootReducer = combineReducers({
    Layout,
    projects,
    projectvalues,
    propertytypes,
    settings,
    propertyavailableentities,
    entityvalues,
    locations,
    propertyservices,
    properties,
    propertysubtypes,
    propertyset,
    propertyvalues,
    contacts,
    companies,
    contactvalues,
    activities,
    activityvalues,
    contactnotes,
    companynotes,
    agents,
    search,
    documents,
    documentvalues,
    notes,
    offers,
    photos,
    groundplans,
    modal,
    exports,
    contactprofiles,
    searchvalues,
    auth,
    reports,
    table,
    diaries,
    imageEditor,
    administration,
})

export default rootReducer
