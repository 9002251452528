import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import i18n from '../../../i18n'
import useApplicationSettings from '../../Hooks/useApplicationSettings'
import Loader from '../Loader'
import flags from './flags'
import langDropdown from '../../../api/langDropdown/hooks'
import { useSelector } from 'react-redux'
import { selectApplicationLanguage } from '../../../store/auth/selectors'

const LanguageDropdown = () => {
    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false)
    const selectedLang = localStorage.getItem('i18nextLng')

    const applicationLanguage = useSelector((state) => selectApplicationLanguage(state))
    const { availableLanguages, mainLanguage } = useApplicationSettings()
    const { mutate } = langDropdown.saveAgentLanguage.useMutation()

    const langsObj = availableLanguages.reduce((acc, lang) => {
        acc[lang.abbreviation] = {
            label: lang.name,
            flag: flags[lang.abbreviation]?.flag,
        }

        return acc
    }, {})

    const isLangsObj = Object.keys(langsObj).length > 0

    const setDefaultLanguage = (language) => {
        i18n.changeLanguage(language)
        localStorage.setItem('i18nextLng', language)
    }
    
    const changeLanguageAction = (lang) => {
        setDefaultLanguage(lang)
        mutate(lang)
    }

    const toggleLanguageDropdown = () => {
        setIsLanguageDropdown(!isLanguageDropdown)
    }

    // effect to set app default language if user did not select one
    // applicationLanguage - user selected lang
    // mainLanguage - app default lang 
    useEffect(() => {
        if (applicationLanguage || !mainLanguage) return

        const defaultLang = mainLanguage.abbreviation
        setDefaultLanguage(defaultLang)
    }, [applicationLanguage, mainLanguage])

    return (
        <React.Fragment>
            <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                    {isLangsObj ? <img src={get(langsObj, `${selectedLang}.flag`)} alt="App Language" height="20" className="rounded" /> : <Loader />}
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    {Object.keys(langsObj).map((key) => (
                        <DropdownItem
                            key={key}
                            onClick={() => changeLanguageAction(key)}
                            className={`notify-item ${selectedLang === key ? 'active' : 'none'}`}
                        >
                            <img src={get(langsObj, `${key}.flag`)} alt="Skote" className="me-2 rounded" height="18" />
                            <span className="align-middle">{get(langsObj, `${key}.label`)}</span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export default LanguageDropdown
