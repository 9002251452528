import { useQuery } from '@tanstack/react-query'
import { fetchApplicationSettings } from './api/index'

export const useFetchApplicationSettings = () => {
    const token = localStorage.getItem('access_token')

    return useQuery({
        queryKey: ['applicationSettings'],
        queryFn: () => fetchApplicationSettings(),
        enabled: !!token,
        refetchOnWindowFocus: false
    })
}
