import { useQueryClient } from '@tanstack/react-query'

const useApplicationSettings = () => {
    const queryClient = useQueryClient()
    const appData = queryClient.getQueryData(['applicationSettings'])

    const { availableLanguages = [], availableTranslationLanguages = [], applicationPackage, mainLanguage } = appData ?? {}

    const locales = availableTranslationLanguages.map((lang) => lang.abbreviation)

    return {
        availableLanguages,
        availableTranslationLanguages,
        applicationPackage,
        locales,
        mainLanguage,
    }
}

export default useApplicationSettings
