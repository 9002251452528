import { useQuery } from '@tanstack/react-query'

export const useCheckAppMarket = (marketId) => {
    const {
        data: { applicationMarket },
    } = useQuery({
        queryKey: ['applicationSettings'],
        queryFn: null,
        enabled: false,
    })

    return applicationMarket?.id === marketId ? true : false
}
