import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button } from 'reactstrap'
import AppPermissionsSwitcher from '../AppPermissionsSwitcher/AppPermissionsSwitcher'
import styles from './Slider.module.css'
import './styles.css'

const NavButton = ({ onClick, buttonClass, iconClass }) => {
    return (
        <Button className={`${styles.navBtn} ${buttonClass}`} color="primary" onClick={onClick}>
            <i className={`mdi ${iconClass}`} />
        </Button>
    )
}

const FullScreen = ({ onClick, isFullscreen }) => {
    const btnStyles = isFullscreen ? styles.fullScreen : styles.notFullScreen

    // image index display
    const imageIndex = document.querySelector('.image-gallery-index')

    if (imageIndex) {
        imageIndex.style.backgroundColor = '#273b4a'
        imageIndex.style.borderRadius = '5px'
        imageIndex.style.padding = '10px'
        imageIndex.style.position = 'absolute'
        imageIndex.style.right = '55px'
        imageIndex.style.top = isFullscreen ? '-37px' : '8px'
    }

    return (
        <Button className={`${styles.fullBtn} ${btnStyles}`} color="primary" onClick={onClick}>
            {isFullscreen ? <i className="mdi mdi-fullscreen-exit"></i> : <i className="mdi mdi-fullscreen"></i>}
        </Button>
    )
}

// TODO: old component used Swiper plugin
// remove it from project if not needed later
const Slider = (props) => {
    const { images, module } = props

    return (
        <AppPermissionsSwitcher permission="AllowedPhotos" photos={images} module={module}>
            {({ photos }) => {
                const galleryImages = photos.map((image) => ({
                    original: image.srcSlider,
                    thumbnail: image.thumb,
                }))

                return photos.length ? (
                    <ImageGallery
                        items={galleryImages}
                        showThumbnails={false}
                        showPlayButton={false}
                        showIndex={true}
                        useBrowserFullscreen={false}
                        renderLeftNav={(onClick) => <NavButton onClick={onClick} buttonClass={styles.navLeft} iconClass={'mdi-chevron-left'} />}
                        renderRightNav={(onClick) => <NavButton onClick={onClick} buttonClass={styles.navRight} iconClass={'mdi-chevron-right'} />}
                        renderFullscreenButton={(onClick, isFullscreen) => <FullScreen onClick={onClick} isFullscreen={isFullscreen} />}
                    />
                ) : null
            }}
        </AppPermissionsSwitcher>
    )
}

export default Slider
