import { useFormik } from 'formik'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom'
import { Alert, Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner } from 'reactstrap'
import * as Yup from 'yup'
import logoLight from '../../assets/images/dimedia-crm-logo-white.png'
import { authenticateWithPassword, selectErrorMessage, selectIsFetching, selectResetPasswordSuccess } from '../../store/auth'
import ObscurePassword from './ObscurePassword'
import ParticlesAuth from './ParticlesAuth'

const Login = (props) => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [obscurePassword, setObscurePassword] = useState(true)

    const { isAuthenticated, errorMessage, isLoading, isSuccess } = useSelector((state) => {
        return {
            isAuthenticated: state.auth.isAuthenticated,
            errorMessage: selectErrorMessage(state),
            isLoading: selectIsFetching(state),
            isSuccess: selectResetPasswordSuccess(state),
        }
    })

    useEffect(() => {
        if (isAuthenticated) {
            handleAuthenticated()
        }
    }, [isAuthenticated])

    const handleAuthenticated = () => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true })
        history.replace(params.continue ? params.continue : '/projects')
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required(t('form.login.error.username')),
            password: Yup.string().required(t('app.common.password.error')),
        }),
        onSubmit: (values) => {
            dispatch(authenticateWithPassword(values.username, values.password, props.history))
        },
    })

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row className="mt-4">
                            <Col lg={12}>
                                <div className="text-center mt-5 mb-5  text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-5">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="fs-16">{t('form.login.title')}</h5>
                                            <p className="text-muted">{t('form.login.message')}</p>
                                        </div>

                                        {isSuccess ? (
                                            <Alert color="success" style={{ marginTop: '13px' }}>
                                                {t('form.resetPassword.success.message')}
                                            </Alert>
                                        ) : null}

                                        {errorMessage?.status === 401 ? (
                                            <Alert color="danger" className="text-center">
                                                {t('form.login.error.unauthorized')}
                                            </Alert>
                                        ) : null}

                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault()
                                                    validation.handleSubmit()
                                                    return false
                                                }}
                                                action="#"
                                            >
                                                <div className="mb-3">
                                                    <Label htmlFor="username" className="form-label">
                                                        {t('form.login.field.username')}
                                                    </Label>
                                                    <Input
                                                        name="username"
                                                        className="form-control"
                                                        placeholder={t('form.login.placeholder.username')}
                                                        type="text"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.username || ''}
                                                        invalid={validation.touched.username && validation.errors.username ? true : false}
                                                    />
                                                    {validation.touched.username && validation.errors.username ? (
                                                        <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="d-flex justify-content-between">
                                                        <Label className="form-label" htmlFor="password-input">
                                                            {t('app.common.password')}
                                                        </Label>
                                                        <Link to="/forgot-password" className="text-muted">
                                                            {t('app.common.password.forgotPassword')}
                                                        </Link>
                                                    </div>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ''}
                                                            type={obscurePassword ? 'password' : 'text'}
                                                            className="form-control pe-5"
                                                            placeholder={t('app.common.password.placeholder')}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={validation.touched.password && validation.errors.password ? true : false}
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}

                                                        {errorMessage ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}

                                                        <ObscurePassword
                                                            errors={validation.errors}
                                                            obscurePassword={obscurePassword}
                                                            setObscurePassword={setObscurePassword}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit" disabled={isLoading}>
                                                        {isLoading ? <Spinner size="sm" /> : t('button.login')}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-3 mx-auto w-50 justify-content-center">
                            <Link to="/register" className="text-primary text-decoration-underline w-25">
                                {t('app.common.register')}
                            </Link>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    )
}

export default withRouter(Login)
