import { store } from '../store'
import { authenticateWithRefreshToken } from '../store/auth/actions'

export const getSearchCriteriaFromStore = (pageName: string) => {
    const pageNameUpperCase = pageName.charAt(0).toUpperCase() + pageName.slice(1)
    const columnSort = store.getState().auth.user.userSettings?.[`${pageName}_columnSort`][0]
    const searchCriteria = store.getState().search?.[`detailSearch${pageNameUpperCase}Criteria`]
    const order = { id: columnSort.desc ? 'desc' : 'asc' }

    return { order: order, criteria: searchCriteria }
}

export function dispatchAuthenticateWithRefreshToken(refreshToken: string) {
    store.dispatch(authenticateWithRefreshToken(refreshToken))
}
