import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Route from './Routes'
import './assets/scss/themes.scss'
import { useFetchApplicationSettings } from './query'

function App() {
    const location = useLocation()
    const { t, i18n } = useTranslation()

    useEffect(() => {
        const [, page] = location.pathname.split('/')
        const translation = i18n.exists(`page.${page}`)
        const pageTitle = page && translation ? `${t(`page.${page}`)} |` : ''
        document.title = `${pageTitle} ${t('app.title')}`
    }, [t, location, i18n])

    // application global data
    useFetchApplicationSettings()

    return (
        <React.Fragment>
            <Route />
            <ToastContainer closeButton={false} draggable autoClose={3000} />
        </React.Fragment>
    )
}

export default App
