import useApplicationSettings from '../Hooks/useApplicationSettings'
import { setFeatures } from './utils'

// use this component to turn on/off application features according to application modes
const AppFeaturesSwitcher = ({ children, ...props }) => {
    const { applicationPackage } = useApplicationSettings()

    if (!applicationPackage) return

    const { name, applicationModules, applicationModuleFeatures } = applicationPackage ?? {}

    const featureProps = {
        ...props,
        applicationModules,
        applicationModuleFeatures,
    }
    let newProps = {}

    switch (name) {
        case 'Basic':
        case 'Start':
        case 'Standard':
            newProps = setFeatures(featureProps)
            break
        default:
            console.error(`No application mode with name: ${name}`)
    }

    return children(newProps)
}

export default AppFeaturesSwitcher
