import QueryString from 'qs'
import { apiReq } from '..'
import { priorityCompareFn } from '../../utils/general'

export const getImagesById = async (id: number, module: string, collection: string) => {
    const params = `noLimit=1&order[priority]=asc&criteria[module]=${module}&criteria[collection]=${collection}`
    const response = await apiReq(`v1/file/parent/${id}`, {
        method: 'GET',
        params: params,
        paramsSerializer: (params: any) => {
            return QueryString.stringify(params)
        },
    })
    return response?.data.items.sort(priorityCompareFn)
}
