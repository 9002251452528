import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import AppPermissionsSwitcher from '../../../../Components/AppPermissionsSwitcher/AppPermissionsSwitcher'
import { action } from '../../../../Components/AppPermissionsSwitcher/constants/actions'
import TableCheckbox from '../../../../Components/Table/TableCheckbox'
import { getCheckboxProps } from '../../../../Components/Table/utils'
import TableImage from '../../../../Components/TableImage/TableImage'
import TableShowHideText from '../../../../Components/TableShowHideText/TableShowHideText'
import responseFields from '../../../../common/constants/responseFields'
import { projectResponseFields } from '../../../../common/response/project'
import { propertyResponseFields } from '../../../../common/response/property'
import { selectApplicationAreaUnit, selectApplicationCurrency } from '../../../../store/settings/selectors'
import { toFullURL } from '../../../../utils/files'
import { findEntityTitle, formatDateTime, formatNumberWithTwoDecimals } from '../../../../utils/general'
import { renderAreaUnit, renderCurrency, renderCurrencyWithUnit } from '../../../../utils/property'

const TableColumns = (onDelete, toggleQuickViewModal, module) => {
    const { currency, areaUnit } = useSelector((state) => ({
        currency: selectApplicationCurrency(state),
        areaUnit: selectApplicationAreaUnit(state),
    }))

    const { t } = useTranslation()

    const columns = useMemo(
        () => [
            {
                Header: ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
                    const checkboxProps = getCheckboxProps(toggleRowSelected, isAllPageRowsSelected, page)

                    return <TableCheckbox {...checkboxProps} />
                },
                Cell: ({ row }) => {
                    return <TableCheckbox {...row.getToggleRowSelectedProps()} disabled={row.original.disabled} />
                },
                id: '#',
                tdClassName: 'text-center align-middle',
            },
            {
                Header: t('app.common.id'),
                id: 'id',
                accessor: 'id',
                filterable: true,
            },
            {
                Header: t('table.header.common.mainPhoto'),
                id: 'mainPhoto',
                accessor: (property) => {
                    if (property.mainPhoto) {
                        return <TableImage src={toFullURL(property.mainPhoto.id)} alt={property.mainPhoto.fileDescription} />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('app.common.agencyId'),
                accessor: propertyResponseFields.INTERNAL_ID,
                filterable: true,
            },
            {
                Header: t('app.common.service'),
                id: propertyResponseFields.PROPERTY_SERVICE,
                accessor: `${propertyResponseFields.PROPERTY_SERVICE}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.status'),
                id: propertyResponseFields.PROPERTY_STATUS,
                accessor: `${propertyResponseFields.PROPERTY_STATUS}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.type'),
                id: propertyResponseFields.PROPERTY_TYPE,
                accessor: `${propertyResponseFields.PROPERTY_TYPE}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('table.header.properties.subtype'),
                id: propertyResponseFields.PROPERTY_SUB_TYPE,
                accessor: `${propertyResponseFields.PROPERTY_SUB_TYPE}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.country'),
                id: propertyResponseFields.COUNTRY,
                accessor: `${propertyResponseFields.COUNTRY}.name`,
                filterable: true,
            },
            {
                Header: t('app.common.county'),
                id: propertyResponseFields.COUNTY,
                accessor: `${propertyResponseFields.COUNTY}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.city'),
                id: propertyResponseFields.CITY,
                accessor: `${propertyResponseFields.CITY}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.widerArea'),
                id: propertyResponseFields.WIDER_AREA,
                accessor: `${propertyResponseFields.WIDER_AREA}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.quarter'),
                id: propertyResponseFields.QUARTER,
                accessor: `${propertyResponseFields.QUARTER}.name`,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.street'),
                accessor: propertyResponseFields.STREET,
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('app.common.streetNumber'),
                accessor: propertyResponseFields.STREET_NUMBER,
                filterable: false,
            },
            {
                Header: t('app.common.owner'),
                id: propertyResponseFields.OWNER,
                accessor: (property) => {
                    const owner = property?.owner[0]?.contactPerson
                    return owner && `${owner?.firstName} ${owner?.lastName}`
                },
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.price', { currency: renderCurrency(currency) }),
                id: propertyResponseFields.PRICE,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('table.header.properties.pricePerUnit', { currencyPerUnit: renderCurrencyWithUnit(currency, areaUnit) }),
                id: propertyResponseFields.PRICE_M2,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.PRICE_M2]
                            ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PRICE_M2])
                            : null}
                    </>
                ),
                filterable: true,
            },
            {
                Header: t('app.common.area', { areaUnit: renderAreaUnit(areaUnit) }),
                id: propertyResponseFields.AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.floorNumber'),
                id: propertyResponseFields.FLOOR_NO,
                Cell: (property) => {
                    const { original: propertyOriginal } = property.row

                    if (!propertyOriginal) {
                        return null
                    }

                    return (
                        <>
                            {propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION]?.length > 0
                                ? propertyOriginal[propertyResponseFields.PROPERTY_FLOOR_POSITION][0].name
                                : propertyOriginal[propertyResponseFields.FLOOR_NO]
                                ? propertyOriginal[propertyResponseFields.FLOOR_NO]
                                : '-'}
                            {propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]
                                ? `/${propertyOriginal[propertyResponseFields.FLOORS_TOTAL_NO]}`
                                : '/-'}
                        </>
                    )
                },
            },
            {
                Header: t('app.common.property.numberOfRooms'),
                accessor: propertyResponseFields.ROOMS_NO,
                filterable: true,
            },
            {
                Header: t('app.common.bedrooms'),
                accessor: propertyResponseFields.BEDROOMS_NO,
                filterable: true,
            },
            {
                Header: t('app.common.agent'),
                id: propertyResponseFields.AGENT,
                Cell: (item) => (
                    <>
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.firstName}{' '}
                        {item.row.original?.[propertyResponseFields.AGENT]?.[0]?.lastName}
                    </>
                ),
                filterable: true,
                minWidth: 150,
            },
            {
                Header: t('table.header.properties.agentMobile'),
                id: 'agentMobile',
                accessor: (property) => property?.agent?.[0]?.mobile,
                filterable: false,
            },
            {
                Header: t('table.header.properties.maxConstructionArea', { areaUnit: renderAreaUnit(areaUnit) }),
                id: `${propertyResponseFields.MAX_CONTRUCTION_AREA}`,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.MAX_CONTRUCTION_AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.seaDistance'),
                id: propertyResponseFields.DISTANCE_SEA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.DISTANCE_SEA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.DISTANCE_SEA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.yard', { areaUnit: renderAreaUnit(areaUnit) }),
                id: propertyResponseFields.AREA_GARDEN,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.AREA_GARDEN]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.AREA_GARDEN])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.property.areaPlot', { areaUnit: renderAreaUnit(areaUnit) }),
                id: propertyResponseFields.PLOT_AREA,
                Cell: (item) =>
                    item.row.original?.[propertyResponseFields.PLOT_AREA]
                        ? formatNumberWithTwoDecimals(item.row.original?.[propertyResponseFields.PLOT_AREA])
                        : null,
                filterable: true,
            },
            {
                Header: t('app.common.heating'),
                id: propertyResponseFields.PROPERTY_HEATING,
                Cell: (property) => (
                    <>
                        {property.row.original?.[propertyResponseFields.PROPERTY_HEATING]?.map((item) => (
                            <div key={item.id}>{item.name}</div>
                        ))}
                    </>
                ),
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.web'),
                id: 'websiteTitle',
                accessor: (property) => {
                    if (property.propertyWebsite?.[0]?.visibility === 1) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('table.header.properties.elevator'),
                id: propertyResponseFields.PROPERTY_EQUIPMENT,
                accessor: (property) => {
                    const elevator = property[propertyResponseFields.PROPERTY_EQUIPMENT]?.find(({ name }) => name.toLowerCase() === 'lift')

                    if (elevator) {
                        return <i className="mdi mdi-check fs-4" />
                    }

                    return null
                },
                filterable: false,
            },
            {
                Header: t('app.common.property.constructionYear'),
                accessor: propertyResponseFields.CONSTRUCTION_YEAR,
                filterable: true,
            },
            {
                Header: t('app.common.property.availableFrom'),
                accessor: propertyResponseFields.AVAILABLE_FROM_DATE,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.AVAILABLE_FROM_DATE]),
                filterable: true,
            },
            {
                Header: t('app.common.offerSource'),
                id: responseFields.PROPERTY_SUPPLY_SOURCE,
                accessor: `${responseFields.PROPERTY_SUPPLY_SOURCE}.name`,
                filterable: true,
            },
            {
                Header: t('view.common.other.created'),
                id: propertyResponseFields.CREATED,
                Cell: (item) => formatDateTime(item.row.original?.[propertyResponseFields.CREATED]),
                filterable: true,
            },
            {
                Header: t('app.common.contractNumber'),
                accessor: propertyResponseFields.CONTRACT_NO,
                filterable: false,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber1'),
                id: `${propertyResponseFields.OWNER}PhoneNumber1`,
                accessor: (property) => property?.owner[0]?.contactPhone[0]?.phoneNumber,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('table.header.properties.ownerPhoneNumber2'),
                id: `${propertyResponseFields.OWNER}PhoneNumber2`,
                accessor: (property) => property?.owner[0]?.contactPhone[1]?.phoneNumber,
                filterable: false,
                minWidth: 150,
            },
            {
                Header: t('app.common.project.label'),
                id: propertyResponseFields.PROJECT,
                accessor: (property) => findEntityTitle(property.project, projectResponseFields.PROJECT_TITLE),
                filterable: true,
                Cell: (item, i) => <TableShowHideText key={i} text={item.cell.value} rowId={item.cell.row.id} numberOfRows={item.rows.length} />,
                minWidth: 200,
            },
            {
                Header: t('app.common.property.designation'),
                accessor: propertyResponseFields.PROPERTY_LABEL,
                filterable: false,
                minWidth: 150,
            },
            {
                id: 'actions',
                thDivClass: 'text-center',
                Cell: (cellProps) => {
                    const itemAgentId = cellProps.row.original.agent?.[0].id

                    return (
                        <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
                            <li className="list-inline-item">
                                <Link
                                    to="#"
                                    title={t('button.view')}
                                    className="btn text-warning d-inline-block p-0"
                                    onClick={() => toggleQuickViewModal(cellProps?.row?.original)}
                                >
                                    <i className="mdi mdi-eye-outline fs-16"></i>
                                </Link>
                            </li>

                            <AppPermissionsSwitcher permission="ModulePermissions" itemAgentId={itemAgentId} module={module} action={action.edit}>
                                {({ hasModulePermission }) =>
                                    hasModulePermission && (
                                        <>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.edit')}
                                                    to={`/properties/${cellProps.row.original.id}/edit`}
                                                    className="text-info d-inline-block edit-item-btn"
                                                >
                                                    <i className="mdi mdi-pencil-outline fs-16"></i>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item edit">
                                                <Link
                                                    title={t('button.delete')}
                                                    to="#"
                                                    className="text-danger d-inline-block remove-item-btn"
                                                    onClick={() => onDelete(cellProps.row.original.id)}
                                                >
                                                    <i className="mdi mdi-trash-can-outline fs-16"></i>
                                                </Link>
                                            </li>
                                        </>
                                    )
                                }
                            </AppPermissionsSwitcher>
                        </ul>
                    )
                },
            },
        ],
        [areaUnit, currency, onDelete, toggleQuickViewModal, module, t]
    )

    return columns
}

export default TableColumns
